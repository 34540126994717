import $ from 'jquery';

// import Swiper
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';

import './display-validation';
import './ajax';

$(function() {

    // Sign in
    $(document).on('submit', '[data-signin]', function (event) {
        event.preventDefault();

        window.ajaxRequest($(this), {
            success: function (response) {
                if (response.success === true) {
                    window.location = '/app/'
                }
            }
        });
    });

    // Sign up
    $(document).on('submit', '[data-signup]', function (event) {
        event.preventDefault();
        window.ajaxRequest($(this), {
            success: function (response) {
                if (response.success === true) {
                    window.location = '/app/'
                }
            }
        })
    });

    // Forgot
    $(document).on('submit', '[data-forgot]', function (event) {
        event.preventDefault();
        window.ajaxRequest($(this), {
            success: function (response) {

                /* eslint-disable no-console */
                console.log(response);
                /* eslint-enable no-console */



            }
        })
    });

    // ------------------------------------------------------




    // popover
    $('[data-toggle*="popover"]').popover();

    // tooltip
    $('[data-toggle="tooltip"]').each(function(){
        var options = { 
            html: true 
        };

        if ($(this)[0].hasAttribute('data-tooltip-color')) {
            options['template'] = 
                '<div class="tooltip ' + $(this).attr('data-tooltip-color') + '" role="tooltip">' + 
                '   <div class="arrow"></div>' + 
                '   <div class="tooltip-inner"></div>' + 
                '</div>';
        }

        $(this).tooltip(options);
    });

    // SWIPER / INITfunc
    function swiperInit(className) {
        (new Swiper (className, {
            // slidesPerView: 'auto',
            // spaceBetween: 32,
            // watchOverflow: true,
            // freeMode: true,

            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            breakpoints: {
                544: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 'auto',
                  spaceBetween: 50,
                },
              }
        }))
    }
    
    // SWIPER for withdrawal
    if($('.s-lp-withdrawal .swiper-container').length) {
        swiperInit('.s-lp-withdrawal .swiper-container');
    }
    
    function helpHandler() {
        $(document).on('click', '.help-search-quick button', function () {
            let $this = $(this),
                $helpSearch = $('.help-search'),
                tag = $this.text();
    
            $helpSearch.find('.form-control').val(tag);
            $helpSearch.submit();
        });
    
        //  Help center faq
        $(document).on('click', '.help-category-head', function () {
            let $this = $(this);
    
            $this.toggleClass('is-active');
            $this.parent().find('.help-category-questions').slideToggle(300);
        });
    
        $(document).on('click', '.help-category-question', function () {
            let $this = $(this);
    
            $this.parent().toggleClass('is-active');
            $this.parent().find('.help-category-answer').slideToggle(300);
        });    
    }

    if($('.help-categories').length) {
        helpHandler();
    }

    // MENU / mobile / open
    $('.header-menu-toggle').on('click', function() {
        $('.s-header').toggleClass('is-active');
    });

    // MENU / mobile / open
    $('.header-list-current').on('click', function() {
        $('.header-list-dropdown').toggleClass('is-active');
    });

    // MODAL / Registration
    $('.btn-reg').on('click', function() {
        $('.service-modal').removeClass('is-active');
        $('#modal-reg').toggleClass('is-active');
    });
    
    // MODAL / Login
    $('.btn-login').on('click', function() {
        $('.service-modal').removeClass('is-active');
        $('#modal-log').toggleClass('is-active');
    });
    
    // MODAL / Forgot
    $('.btn-forgot').on('click', function() {
        $('.service-modal').removeClass('is-active');
        $('#modal-forgot').toggleClass('is-active');
    });


    // MODAL / Close
    $('.service-modal .modal-close').on('click', function() {
        $('.service-modal').removeClass('is-active');
    });

    // HEADER / bg on scroll
    if($("body").hasClass('p-lp')) {
        $(document).scrollTop() >= 100 ?
            $('.s-header').removeClass('no-bg') :
            $('.s-header').addClass('no-bg');

        $(document).on('scroll', function() {
            $(document).scrollTop() >= 100 ?
                $('.s-header').removeClass('no-bg') :
                $('.s-header').addClass('no-bg');

        });
    }

    // Turn loader off
    $('[data-loader]').removeClass('is-active');
})
