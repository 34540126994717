import $ from 'jquery';

/**
 * $('#form').displayValidation({
 *     'form1.first_name': ['Ошибка валидации']
 * });
 * $('#form').displayValidation('clear');
 * $('#form').displayValidation('clearElement', '[name=\'form1[first_name]\']');
 */
export default (function ($) {

    /*let defaults = {

    };*/

    let methods = {

        // error rendering
        init: function (errors) {
            clearForm(this);

            $(document).on('input', this.find('.form-control'), function (event) {
                clearDisplayElement($(event.target));
            });

            for (let error in errors) {
                renderError(this, error, errors[error][0]);
            }
        },

        // clear all
        clear: function () {
            clearForm(this);
        },

        // clear specific element
        clearElement: function (selector) {
            clearDisplayElement(this.find(selector));
        },
    };

    /*function renderSuccess($form, field, error) {
        let $element = $form.find("[name='" + getElementName(field) + "']"),
            $elementError = $element.parent().find('.valid-feedback');

        $element.removeClass('has-error').addClass('is-valid');
        $elementError.html(error);
    }*/

    function renderError($form, field, error) {
        let $element = $form.find("[name='" + getElementName(field) + "']"),
            $elementError = $element.parent().find('.form-help');

        // $element.parents('.form-group').removeClass('is-valid').addClass('has-error');
        $element.parents('.form-group').addClass('has-error');
        $elementError.html(error);

        if ($element.attr('type') === 'hidden') {
            // $element.parent().find('input').removeClass('is-valid').addClass('has-error');
            $element.parent().find('input').parents('.form-group').removeClass('is-valid').addClass('has-error');
        }
    }

    function clearForm($form) {
        let $elements = $form.find('.form-control');

        $elements.each(function (i, element) {
            clearDisplayElement($(element));
        });
    }

    function clearDisplayElement($element) {

        $element.parents('.form-group').removeClass('has-error');
        // $element.removeClass('is-valid');

        $element.parent().find('.form-help').text('');
    }

    function getElementName(name) {
        let elementName = name;
        if (name.indexOf('.') + 1) {
            let nameSegments = name.split('.');
            elementName = nameSegments.shift();
            for (let i in nameSegments) {
                if (nameSegments.hasOwnProperty(i) === false) {
                    continue;
                }
                elementName += '[' + nameSegments[i] + ']';
            }
        }
        return elementName;
    }

    $.fn.displayValidation = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Метод с именем ' + method + ' не существует для jQuery.displayValidation');
        }
    };

})($);